.detail-area-contact{
    position: relative;
    overflow: hidden;
    .section-heading{
        text-align: left;
        .title-heading{
            color: var(--Cement);
            font-size: 36px;
            @media screen and (max-width: 961px) {
                font-size: 30px !important;
            }
        }
    }
    .contact-info{
        margin-bottom: 20px;
        .contact-info__icon{
            margin-right: 20px;
            img{
                width: 6;
            }
        }
        .media-body{
            h2{
                font-size: 1.5rem;
                margin: 1rem 0;
                color: var(--Cement);
                font-family: var(--font-TrajanPro-Regular);
                @media screen and (max-width: 961px) {
                    font-size: 24px !important;
                }
            }
            p{
                font-family: var(--font-AvenirLTStd-Book);
                @media (min-width: 992px){
                    width: 50%;
                }
                @media (max-width: 992px){
                    width: 100%;
                }
            }
        }
    }
    .sub{
        color: var(--Cement);
        font-size: 25px;
        margin-bottom: 20px;
        font-weight: 100;
        font-family: var(--font-TrajanPro-Regular);
    }
    .form-contact{
        .form-group{
            margin-bottom: 20px;
            .form-control{
                border-radius: 20px;
                border: 1px solid var(--Cement);
                font-family: var(--font-AvenirLTStd-Book);
                &:focus{
                    outline: none;
                    box-shadow: none;
                }
                &::placeholder {
                    text-align: center;
                    opacity: .75;
                }
            }
        }
        textarea{
            &.form-control{
                border-radius: 20px;
                border: 1px solid var(--Cement);
                font-family: var(--font-AvenirLTStd-Book);
                margin-bottom: 20px;
                &:focus{
                    outline: none;
                    box-shadow: none;
                }
                &::placeholder {
                    text-align: left;
                    opacity: .75;
                }
            }
        }
        .btn-contact{
            border-radius: 50px;
            font-family: var(--font-AvenirLTStd-Book);
            background-color: var(--Cement);
            border: none;
            padding: .5rem 2rem;
            &:focus{
                outline: none;
                box-shadow: none;
            }
            &:hover{
                background-color: var(--Loblolly);
            }
        }
    }
}
.hero-news-area{
    position: relative;
    .overlay{
        position: absolute;
        width: 100%;
        z-index: 10;
        .hero-content {
            position: relative;
            width: 100%;
            overflow: hidden;
            h1 {
                font-size: 27px;
                color: var(--White);
                margin: 1rem 0;
                font-family: var(--font-AvenirLTStd-Book);
            }
        }
    }
}
.detail-news{
    position: relative;
    overflow: hidden;
    margin: 0;
    .section-body{
        position: relative;
        h1{
            font-size: 30px;
            color: var(--Cement);
            margin: 1rem 0;
            font-family: var(--font-TrajanPro-Regular);
        }
        .single-news-post{
            margin: 40px 0;
            background-color: var(--White);
            .post-right{
                position: relative;
                h3{
                    text-transform: uppercase;
                    color: var(--Cement);
                    font-family: var(--font-TrajanPro-Regular);
                    display: -webkit-box;
                    max-height: calc(2 * 2rem);
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    letter-spacing: normal;
                    cursor: pointer;
                }
                div{
                    margin: 1rem 0;
                    font-family: var(--font-AvenirLTStd-Book);
                    display: -webkit-box;
                    max-height: calc(3 * 1.7rem);
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 3;
                    letter-spacing: normal;
                }
                a{
                    &.btn{
                        font-family: var(--font-AvenirLTStd-Book);
                        border: none;
                        border-radius: 50px;
                        padding: .5rem 2rem;
                        color: var(--White);
                        background-color: var(--Cement);
                        &:hover{
                            background-color: var(--Loblolly);
                            color: var(--White);
                            border: 1px solid (--White);
                        }
                    }
                }
            }
        }
        .thumb{
            position: relative;
            overflow: hidden;
            img{
                width: 100%;
            }
        }
        .meta{
            margin: 15px 0;
            li{
                font-size: 15px;
                color: var(--Cement);
                opacity: .7;
            }
        }
        .share{
            position: relative;
            margin: 1rem 0;
            p{
                font-family: var(--font-AvenirLTStd-Book);
                font-weight: bold;
                font-size: 18px;
                color: var(--Sandal);
            }
            ul{
                li{
                    margin: 0 10px;
                }
            }
        }
        .more-news{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            font-family: var(--font-AvenirLTStd-Book);
            p{
                font-size: 14px;
                color: var(--Black);
                width: auto;
                &:first-child{
                    height: 1px;
                    width: 89%;
                    display: inline-block;
                    border-radius: 5px;
                    bottom: 26px;
                    // position: absolute;
                    margin: 5px 20px 5px 0px;
                    background-color: var(--Cement);
                }
            }
        }
    }
}
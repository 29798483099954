#header{
    position: relative;
    z-index: 999;
    background-color: var(--White90);
    .navbar{
        .navbar-toggler{
            border: none;
            color: var(--Cement);
        }
        .navbar-brand{
            margin-right: 0;
            width: 200px;
        }
        .navbar-collapse{
            .navbar-nav{
                .nav-item{
                    position: relative;
                    padding: .5rem 1rem;
                    .nav-link{
                        position: relative;
                        transition: all .5s;
                        font-size: 13px;
                        padding: 0;
                        text-transform: uppercase;
                        color: var(--SandDune);
                        text-shadow: 0px 0px var(--Cement);
                        font-family: var(--font-TrajanPro-Regular);
                    }
                    &.dropdown{
                        .dropdown-menu{
                            border: none;
                            border-radius: 0;
                            text-align: center;
                            .dropdown-item{
                                padding: 10px;
                                color: var(--Cement);
                                font-family: var(--font-TrajanPro-Regular);
                                margin: 10px;
                                width: auto;
                                &:last-child{
                                    border-bottom: none;
                                }
                                &:hover{
                                    color: var(--Cement);
                                    background-color: var(--White);
                                    border: 1px solid var(--Cement);
                                    border-radius: 25px;
                                }
                            }
                        }
                        .nav-link{
                            &::after{
                                content: "";
                                display: none;
                            }
                        }
                    }
                    &:hover{
                        border: 1px solid var(--Cement);
                        border-radius: 25px;
                    }
                }
            }
            @media screen and (max-width: 961px){
                background-color: #FFFFFF;
                position: absolute;
                left: -334px;
                top: 85px;
                z-index: 100;
                height: 100vh;
                width: 90%;
                display: block;
                text-align: left;
                opacity: 0;
                transition: all 500ms;
                transform: scaleX(0);
                padding: 1rem;
            }
        }
        .img-pp{
            img{
                width: 69px;
            }
        }
    }
    &.sticky{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        animation: slideInDown 0.5s forwards;
        -webkit-animation: slideInDown 0.5s forwards;
        -moz-animation: slideInDown 0.5s forwards;
        -o-animation: slideInDown 0.5s forwards;
        background: var(--White);
        border-bottom: 1px solid transparent;
        -webkit-box-shadow: 4px 0 20px -5px rgba(0, 0, 0, .2);
        box-shadow: 4px 0 20px -5px rgba(0, 0, 0, .2);
        .navbar{
            .navbar-collapse{
                .navbar-nav{
                    .nav-item{
                        .nav-link{
                            font-size: 14px;
                            color: var(--SandDune);
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 961px) {
    #header{
        background-color: var(--White);
        .navbar{
            .navbar-collapse{
                .navbar-nav{
                    .nav-item{
                        .nav-link{
                            font-size: 15px;
                            color: var(--Cement);
                        }&.dropdown{
                            .dropdown-menu{
                                padding: 10px 10px;
                                text-align: left;
                                .dropdown-item{
                                    &:last-child{
                                        border-bottom: none;
                                    }
                                    &:hover{
                                        color: var(--White);
                                        background-color: var(--Loblolly);
                                    }
                                }
                            }
                            .nav-link{
                                &::after{
                                    display: inline-block;
                                    margin-left: .255em;
                                    vertical-align: .255em;
                                    content: "";
                                    border-top: .3em solid;
                                    border-right: .3em solid transparent;
                                    border-bottom: 0;
                                    border-left: .3em solid transparent;
                                }
                            }
                        }
                    }
                }
                &.show{
                    position: absolute;
                    top: 62.08px;
                    opacity: 1;
                    left: 0;
                    transform: scaleX(1);
                }
            }
        }
        &.sticky{
            .navbar{
                .navbar-collapse{
                    &.show{
                        top: 62.08px;
                    }
                }
            }
        }
    }
}
.detail-area-about{
    position: relative;
    overflow: hidden;
    .section-heading{
        img{
            margin-bottom: 40px;
        }
        .title-heading{
            text-align: left;
        }
    }
    .section-body{
        p{
            padding-bottom: 1rem;
            font-family: var(--font-AvenirLTStd-Book);
        }
        h2{
            padding-top: 1rem;
            padding-bottom: 2rem;
            color: var(--Cement);
            font-family: var(--font-TrajanPro-Regular);
        }
        .detail-img{
            text-align: center;
            img{
                padding: 2rem 0;
                text-align: center;
            }
        }
    }
}